
import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";
import { CustomDate } from "@/mixins/custom-date";
import Loader from "@/components/General/Loader.vue";
import { Notification } from "@/models/notification.interface";

@Component({
  components: {
    Loader,
  },
})
export default class Home extends mixins(Navigation, CustomDate) {
  loader = false;

  private async mounted() {
    await this.getSystemSummary();
  }

  private async getSystemSummary() {
    this.loader = true;
    await this.$store.dispatch("summary/getSystemSummary").catch(() => {
      const Error: Notification = {
        message: this.$tc("Views.sh-e1"),
        timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
        top: true,
        bottom: false,
        left: false,
        right: false,
        currentPath: this.$route.fullPath,
        error: true,
      };

      this.$store.dispatch("notifications/showNotification", Error);
    });
    this.loader = false;
  }

  private get systemSummary() {
    return this.$store.getters["summary/getSystemSummary"];
  }
  private get userAlias(): string {
    let user = this.$store.getters["authentication/getUser"];
    if (user) {
      return user.alias;
    } else {
      return "";
    }
  }
  private get image(): string {
    let logo = this.$store.getters["system/getLogo"];
    if (logo) {
      return logo.url;
    } else {
      return "";
    }
  }
}
